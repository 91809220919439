import { RefreshTokenResponse } from './../Pages/Login/interfaces/login';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthTokenExpiredInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private injector: Injector,
    private toastr: ToastrService,
    private tokenManager: AuthService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* Acá se agrega el token al header de los http request. */
    req = this.setTokenToHeader(req);
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            /* Se verifica si el Token ha vencido */
            return this.handle401Error(req, next);
          } else {
            if (error.status === 400) {
              /* En caso de que el REFRESH TOKEN haya vencido, le envía al login. Si no es el caso, maneja el error normalmente */
              return this.handle400Error(error, req.url);
            } else {
              /* Si es un error cualquiera viene por aquí*/
              return throwError(error);
            }
          }
        }),
      );
  }

  setTokenToHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.tokenManager.getToken();
    if (!req.url.includes('refreshToken') ||
      !req.url.includes('login') ||
      !req.url.includes('parametrosLogin') ||
      !req.url.includes('passwords')) {
      if (token) {
        const clonedRequest = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        });
        return clonedRequest;
      } else {
        // No se agregó token porque no había
        return req;
      }
    } else {
      return req;
    }
  }

  handle400Error(error: HttpErrorResponse, requesUrl) {
    if (error.status === 400 && requesUrl.includes('refreshToken')) {
      this.isRefreshingToken = false;
      this.tokenManager.goToLogin();
      this.toastr.warning('La sesión expiró, por favor, ingrese de nuevo', 'Atención');

    }
    return throwError(error);
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    /* Se agrega el método distinctUntilChanged para evitar que haya iteraciones excesivas en los switchMap */
    if (this.isRefreshingToken) {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        distinctUntilChanged(),
        switchMap(token => {
          return next.handle(this.addNewAccessTokenToHeaders(request, token));
        }));

    } else {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      return this.tokenManager.renewAccessToken().pipe(
        distinctUntilChanged(),
        switchMap((token: RefreshTokenResponse) => {
          this.isRefreshingToken = false;
          this.tokenSubject.next(token.data.access_token);
          return next.handle(this.addNewAccessTokenToHeaders(request, token.data.access_token, token.data.refresh_token));
        })
      );
    }
  }

  addNewAccessTokenToHeaders(req: HttpRequest<any>, token: string, newRefreshToken: string = ''): HttpRequest<any> {
    req = req.clone({
    });

    this.injector.get(AuthService).setToken(token);

    if (newRefreshToken !== '') {
      this.injector.get(AuthService).setRefreshToken(newRefreshToken);
    }

    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    });
  }
}
