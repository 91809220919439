<div class="app-page-title p-2">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}} fa-border fa-solid" style="border-color: #116073;">
        </i>
      </div>
      <div
        style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
        <span style="font-size: 1.3rem;">{{heading}}</span>
        <div class="page-title-subheading" style="font-size: 1.1rem;">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions" *ngIf="showButton">
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn"
        style="background-color: #116073; color: white; margin-bottom: 10px;" (click)="clicked.emit(true)">
        <i class="mr-2 fa fa-plus"></i>
        {{textButton}}
      </button>
    </div>
  </div>
</div>