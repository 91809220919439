import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwalComponentComponent } from './swal-component.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SwalComponentComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [SwalComponentComponent]
})
export class SwalComponentModule { }
