import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/auth/user';
import { LocalStorageService } from 'src/app/util/local-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  public usuario: User;

  constructor(private localStorageService : LocalStorageService) { }

  ngOnInit() {
    this.usuario= this.localStorageService.getUserFromStorage();
  }

}
