<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
<!--  <div class="logo-src"></div>-->
  <div class="app-header__content">
    <div class="app-header-left">
      <div class="logo-src" *ngIf="globals.toggleSidebar"
           style="cursor: pointer" routerLink="/inicio" routerLinkActive="active-item"></div>
      <!--<app-search-box></app-search-box>-->
      <!--<app-mega-menu></app-mega-menu>-->
    </div>
    <div class="app-header-right">
      <!--<app-dots></app-dots>-->
      <button mat-icon-button title="Ver documentación" (click)="verDocumentacion()" style="background-color: white; padding-left: 1%; padding-right: 1%;">
        <div class="documentacion-logo"></div>
      </button>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
        <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
                (click)="toggleHeaderMobile()">
            <div class="btn-icon-wrapper">
                <i class="fa fa-ellipsis-v"></i>
            </div>
        </button>
    </span>
  </div>
</div>
