<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="header__pane">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : !globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <div class="logo-src ml-3" *ngIf="!globals.toggleSidebar" style="cursor: pointer" routerLink="/inicio"
      routerLinkActive="active-item"></div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [config]="config">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header" *ngIf="hasPanelHeaderTitle">Módulo Principal</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="administracionMenu" *ngIf="permissionController.modulosObj['ADM']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/administracion" routerLinkActive="active-item"
                    [routerLinkActiveOptions]="{exact: true}">
                    <i class="vsm-icon pe-7s-culture opacity-7"></i>
                    <!-- <a routerLink="/administracion" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}">
                    </a> -->
                    <span class="vsm-title">Administración</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_personas/list306'">
                        <a routerLink="/administracion/personas" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Personas</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_usuarios/list300'">
                        <a routerLink="/administracion/usuarios" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Usuarios</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_roles/list355'">
                        <a routerLink="/administracion/roles" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Roles</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_parametros/list331'">
                        <a routerLink="/administracion/parametros-sistema" routerLinkActive="active-item"
                          class="vsm-link"><span class="vsm-title">Parámetros del Sistema</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_modulos/list339'">
                        <a routerLink="/administracion/modulos" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Módulos</span></a>
                      </div>
                      <div class="vsm-item"
                        *ngxPermissionsOnly="['ADM/mant_auditoria/list336', 'ADM/mant_auditoria/list337']">
                        <a routerLink="/administracion/auditoria" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Auditoría</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>

            <div class="vsm-item" *ngIf="permissionController.modulosObj['PAR']">
              <a routerLink="/administracion/parametricos" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-tools opacity-7"></i>
                <span class="vsm-title">Paramétricos del Sistema</span>
              </a>
            </div>

            <div class="vsm-item" *ngxPermissionsOnly="'CHE/mant_checklist/list500'">
              <a routerLink="/estructura-checklist" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-note opacity-7"></i>
                <span class="vsm-title">Estructura Checklist</span>
              </a>
            </div>

            <div class="vsm-item" *ngxPermissionsOnly="'CHE/mant_desvio_instancia/list530'">
              <a routerLink="/checklist" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-note2 opacity-7"></i>
                <span class="vsm-title">Checklist</span>
              </a>
            </div>

            <div class="vsm-item" *ngxPermissionsOnly="'CHE/mant_desvio/list529'">
              <a routerLink="/desvios" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-ribbon opacity-7"></i>
                <span class="vsm-title">Desvíos</span>
              </a>
            </div>

            <div class="vsm-item" *ngxPermissionsOnly="['CHE/mant_pregunta_adicional_respuesta/list549']">
              <a routerLink="/reportes" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph3 opacity-7"></i>
                <span class="vsm-title">Reportes</span>
              </a>
            </div>


          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- <div class="app-footer">
    <div class="app-footer__inner"></div>
  </div> -->
  <div class="test" style="height: 69px; background-color: #116073;"></div>
</div>