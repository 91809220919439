import { DatosNoEncontradosModule } from './util/datos-no-encontrados/datos-no-encontrados.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxLoadingModule } from 'ngx-loading';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TextMaskModule } from 'angular2-text-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { ThemeOptions } from './theme-options';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';
import { GaugeModule } from 'angular-gauge';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { Globals } from './Globals';
import { LayoutModule } from './Layout/layout.module';
import { StorageServiceModule } from 'angular-webstorage-service';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { AuthTokenExpiredInterceptor } from './auth/auth-token-expired-interceptor';
import { LottieModule  } from 'ngx-lottie';
import player from 'lottie-web';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';
import { SpinnerModule } from './util/spinner/spinner.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BotonFiltroModule } from './util/boton-filtro/boton-filtro.module';
import { BotoneraEdicionModule } from './util/botonera-edicion/botonera-edicion.module';
import { BotoneraCreacionModule } from './util/botonera-creacion/botonera-creacion.module';
import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {RegistrarUsuarioComponent} from './Pages/Login/login/registrar-usuario/registrar-usuario.component';
import {OlvideContrasenhaComponent} from './Pages/Login/login/olvide-contrasenha/olvide-contrasenha.component';
import { NumberFormatPipe } from './util/pipes/numberFormat.pipe';
import {SwalComponentModule} from './util/swal-component/swal-component.module';
// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';
import { OrderByPipe } from './util/pipes/order-by.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    LoginComponent,
    PerfilComponent,
    HomeComponent,
    RecuperarPasswordComponent,
    RecuperarExitoComponent,
    AdministracionComponent,
    RegistrarUsuarioComponent,
    OlvideContrasenhaComponent,
    NumberFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    LayoutModule,
    StorageServiceModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SweetAlert2Module.forRoot({}),
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AngularStickyThingsModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    TextMaskModule,

    // Charts
    GaugeModule.forRoot(),

    // Angular Material Components
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTreeModule,
    MatRippleModule,
    MatCheckboxModule,
    LottieModule.forRoot({ player: playerFactory }),
    SpinnerModule,
    MatButtonModule,
    FlexLayoutModule,
    BotonFiltroModule,
    BotoneraEdicionModule,
    DatosNoEncontradosModule,
    BotoneraCreacionModule,
    // Maps Module
    LeafletModule,
    SwalComponentModule,
    // Permisos
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenExpiredInterceptor,
      multi: true
    }, { provide: MAT_DATE_LOCALE, useValue: 'es-US' },
    ConfigActions,
    ThemeOptions,
    Globals,
    NgbActiveModal
  ],
    exports: [
        PageTitleComponent,
        PagesLayoutComponent,
        DrawerComponent
    ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(  private ngRedux: NgRedux<ArchitectUIState>,
                private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
