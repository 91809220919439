import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { UsuariosComponent } from './Pages/Administracion/usuarios/usuarios.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { DetalleUsuarioComponent } from './Pages/Administracion/usuarios/detalle-usuario/detalle-usuario.component';
import { CrearUsuarioComponent } from './Pages/Administracion/usuarios/crear-usuario/crear-usuario.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recuperar', component: RecuperarPasswordComponent,
  },
  {
    path: 'recuperar/exito', component: RecuperarExitoComponent,
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'prefix'
      },
      // Administracion
      {
        path: 'inicio',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        data: { extraParameter: 'administracionMenu' }
      },
      {
        path: 'administracion',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            canActivate: [AuthGuardService],
            component: AdministracionComponent
          },
          {
            path: 'personas',
            loadChildren: () => import('./Pages/Administracion/personas/personas.module').then(m => m.PersonasModule),
          },
          {
            path: 'usuarios',
            loadChildren: () => import('./Pages/Administracion/usuarios/usuarios.module').then(m => m.UsuariosModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'modulos',
            loadChildren: () => import('./Pages/Administracion/modulos/modulos.module').then(m => m.ModulosModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'roles',
            loadChildren: () => import('./Pages/Administracion/roles/roles.module').then(m => m.RolesModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'auditoria',
            loadChildren: () => import('./Pages/Administracion/auditoria/auditoria.module').then(m => m.AuditoriaModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametros-sistema',
            loadChildren: () => import('./Pages/Administracion/parametros-sistema/parametros-sistema.module').then(m => m.ParametrosSistemaModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametricos',
            loadChildren: () => import('./Pages/Administracion/parametricos/parametricos.module').then(m => m.ParametricosModule),
            data: { extraParameter: 'administracionMenu' }
          },
        ],
        data: { extraParameter: 'administracionMenu' }
      },
      // Administracion
      { path: 'usuario/perfil-usuario', component: PerfilComponent, data: { extraParameter: 'administracionMenu' } },

      // Notificaciones
      {
        path: 'notificaciones',
        loadChildren: () => import('./Pages/Notificaciones/notificaciones.module').then(m => m.NotificacionesModule),
        data: { extraParameter: 'notificacionesMenu' }
      },
      // Estructura CheckList
      {
        path: 'estructura-checklist',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/estructura-check-list/estructura-check-list.module').then(m => m.EstructuraCheckListModule),
        data: { extraParameter: 'estructuraMenu' }
      },
      // CheckList
      {
        path: 'checklist',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/check-list/check-list.module').then(m => m.CheckListModule),
        data: { extraParameter: 'checklistMenu' }
      },
      // Desvios
      {
        path: 'desvios',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/desvios/desvios.module').then(m => m.DesviosModule),
        data: { extraParameter: 'desviosMenu' }
      },// Reportes
      {
        path: 'reportes',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/reportes/reportes.module').then(m => m.ReportesModule),
        data: { extraParameter: 'reportesMenu' }
      }
    ]

  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
