<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="container">
  <ng-template [ngIf]="!cargando && adminDasboard" [ngIfElse]="showSpinner">
    <div class="row mt-4">
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/personas" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Personas</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntPersonas">
                  <span>{{adminDasboard.cntPersonas | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/personas" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-uppercase mb-1 text-white">Personas</div>
              <div class="widget-subheading opacity-5 subheading-size text-white">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntPersonas">
                      <span class="text-white">{{adminDasboard.cntPersonas | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                  <!-- <div class="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <div class="circle-progress">
                      <div class="progress-circle-wrapper">
                        <div class="round-progress-text">
                          <span>42%</span>
                          <round-progress
                            [current]="42"
                            [max]="100"
                            [color]="'#52c44f'"
                            [background]="'#f0f0f0'"
                            [radius]="62"
                            [stroke]="6"
                            [semicircle]="false"
                            [rounded]="true"
                            [clockwise]="false"
                            [responsive]="true"
                            [duration]="1400"
                            [animation]="'easeInOutQuart'"
                            [animationDelay]="0"></round-progress>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/usuarios" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Usuarios</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntUsuarios">
                  <span>{{adminDasboard.cntUsuarios | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/usuarios" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-uppercase mb-1 text-white">Usuarios</div>
              <div class="widget-subheading opacity-5 subheading-size text-white">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntUsuarios">
                      <span class="text-white">{{adminDasboard.cntUsuarios | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/parametros-sistema" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Parámetros del Sistema</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntParametros">
                  <span>{{adminDasboard.cntParametros | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/parametros-sistema" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-uppercase mb-1 text-white">Parámetros del Sistema</div>
              <div class="widget-subheading opacity-5 subheading-size text-white">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntParametros">
                      <span class="text-white">{{adminDasboard.cntParametros | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/roles" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Roles</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntRoles">
                  <span>{{adminDasboard.cntRoles | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/roles" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-uppercase mb-1 text-white">Roles</div>
              <div class="widget-subheading opacity-5 text-white subheading-size">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntRoles">
                      <span class="text-white">{{adminDasboard.cntRoles | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/auditoria" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Registros de Auditorías</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntAuditorias">
                  <span>{{adminDasboard.cntAuditorias | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/auditoria" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-white text-uppercase mb-1">Registros de Auditorías</div>
              <div class="widget-subheading opacity-5 text-white subheading-size">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntAuditorias">
                      <span class="text-white">{{adminDasboard.cntAuditorias | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Países</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntPaises">
                  <span>{{adminDasboard.cntPaises | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-white text-uppercase mb-1">Países</div>
              <div class="widget-subheading opacity-5 text-white subheading-size">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntPaises">
                      <span class="text-white">{{adminDasboard.cntPaises | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Departamentos</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntDepartamentos">
                  <span>{{adminDasboard.cntDepartamentos | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-white text-uppercase mb-1">Departamentos</div>
              <div class="widget-subheading opacity-5 text-white subheading-size">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntDepartamentos">
                      <span class="text-white">{{adminDasboard.cntDepartamentos | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xl-4">
        <div class="card mb-3 widget-content" style="max-height: 109px; min-height: 109px; position: relative;">
          <button class="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-left btn-block"
            routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Ciudades</div>
                <div class="widget-subheading">Total</div>
              </div>
              <div class="widget-content-right">
                <div class="widget-numbers number-colored rounded-circle text-center widget-numbers-bordered"
                  *ngIf="adminDasboard.cntCiudades">
                  <span>{{adminDasboard.cntCiudades | numberFormat}}</span></div>
              </div>
            </div>
          </button>
        </div>
      </div> -->
      <div class="col-md-6 col-xl-4">
        <div
          class="widget-chart widget-chart2 text-left mb-3 card-shadow-primary border-primary card widget-chart-hovered card-hover"
          routerLink="/administracion/parametricos/paises" routerLinkActive="active-item">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <div class="widget-title text-white text-uppercase mb-1">Ciudades</div>
              <div class="widget-subheading opacity-5 text-white subheading-size">Total</div>
              <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                <div class="widget-chart-flex align-items-center">
                  <div>
                    <span class="opacity-5 pr-1">
                      <!-- <fa name="angle-up"></fa> -->
                    </span>
                    <ng-template [ngIf]="adminDasboard.cntCiudades">
                      <span class="text-white">{{adminDasboard.cntCiudades | numberFormat}}</span>
                    </ng-template>
                    <!-- <small class="opacity-5 pl-1">%</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #showSpinner>
    <app-spinner></app-spinner>
  </ng-template>
</div>