import { Injectable } from '@angular/core';
import { LocalStorageService } from './util/local-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { ParametrosData } from './Pages/Login/interfaces/parametros';
import { environment } from '../environments/environment';

@Injectable()
export class Globals {
  /* ESOLUTIONS BASEBACKEND */
  // API_ENDPOINT_ES = 'http://esolutions.com.py:8080/basebackend/api';
  // FILE_ENDPOINT = 'http://esolutions.com.py:8080/basebackend';
  /* BASEBACKEND */
  API_ENDPOINT_ES = environment.API_ENDPOINT_ES;
  FILE_ENDPOINT = environment.FILE_ENDPOINT;
  API_ENDPOINT = './assets/data/';
  API_ENDPOINTTEST = 'http://xxx/php-firebase-onesignal';
  DOCS_ENDPOINT = 'https://pyxisdoc.hbsa.com.br/';

  public parametros: ParametrosData[];

  /* Habilita la creación de usuarios desde la ventana del login */
  registroDeUsuarios = true;

  /* Check para ver si es Desarrollo o Producción
      Útil para ver donde apunta el ENDPOINT actual
  */
  isProductionMode = environment.production;

  constructor(private localStorageService: LocalStorageService) {
  }

  getHeader(token = null) {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
  }

  makeUrl(nombreServicio: string, search: any | undefined, pagination: any | undefined): string {
    let url: string = this.API_ENDPOINT_ES + nombreServicio;
    const params = [];

    for (const prop in search) {
      if (search[prop] !== undefined && search[prop] !== null && search[prop] !== '') {
        params.push(prop + '=' + search[prop]);
      }
    }

    for (const prop in pagination) {
      if (pagination[prop] !== undefined) {
        params.push(prop + '=' + pagination[prop]);
      }
    }

    if (params.length) {
      url += '?' + params.join('&');
    }

    return url;
  }

}
