import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html'
})
export class FooterMenuComponent implements OnInit {

  date = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
  }

  onLogoClick(): void {
    const urlEsolutions = 'http://www.enterprisesolutions.com.py/';
    window.open(urlEsolutions, '_blank');
  }


}
